<template>
  <!-- Nested Row within Card Body -->
  <div class="row justify-content-center">
    <div class="col-lg-4">
      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <div class="p-5">
            <div class="text-center mb-4">
              <img src="@/assets/logo.svg" class="mt-4" alt="Manageo" />
              <h1>Point de vente</h1>
            </div>
            <div v-if="error" class="alert alert-danger">
              <p class="text-center">{{ error }}</p>
            </div>
            <Form @submit="onSubmit" class="user" :validation-schema="schema">
              <label>Email <span class="text-danger">*</span></label>
              <div class="form-group">
                <Field name="email" v-slot="{ meta, field }">
                  <input
                    :class="{ 'is-invalid': meta.touched && !meta.valid }"
                    v-bind="field"
                    type="email"
                    class="form-control"
                    id="exampleInputEmail"
                    aria-describedby="emailHelp"
                    placeholder="Adresse mail"
                  />
                </Field>
                <span class="text-danger d-block"
                  ><ErrorMessage name="email"
                /></span>
              </div>
              <div class="form-group">
                <label>Mot de Passe <span class="text-danger">*</span></label>
                <Field name="password" v-slot="{ meta, field }">
                  <input
                    :class="{ 'is-invalid': meta.touched && !meta.valid }"
                    v-bind="field"
                    type="password"
                    class="form-control"
                    id="exampleInputPassword"
                    placeholder="Mot de passe"
                  />
                </Field>
                <span class="text-danger d-block"
                  ><ErrorMessage name="password"
                /></span>
              </div>
              <div class="form-group" v-if="accounts.length">
                <label label
                  >Compte Manageo <span class="text-danger">*</span></label
                >
                <Field name="host" as="select" class="form-control">
                  <option value="" disabled>Compte Manageo</option>
                  <option
                    :value="account.host"
                    v-for="account in accounts"
                    :key="account.id"
                  >
                    {{ account.host }}
                  </option>
                </Field>
              </div>
              <button
                class="btn btn-primary btn-block"
                :disabled="login_submittion"
              >
                Connexion
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { mapActions, mapMutations } from "vuex";

const authservice = new AuthService();

export default {
  components: {
    Form,
    Field,
  },
  data() {
    const schema = yup.object({
      email: yup
        .string()
        .required("Ce champ est obligatoire")
        .email("L'email doit être valide"),
      password: yup.string().required("Ce champ est obligatoire"),
    });
    return {
      userAccounts: [],
      error: null,
      schema,
      showHost: false,
      accounts: [],
      login_submittion: false,
    };
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    ...mapMutations("auth", {
      showLoading: "loadSpinner",
    }),
    async onSubmit(values) {
      if (values.host) {
        this.authUser({
          email: values.email,
          password: values.password,
          host: values.host,
        });
        return;
      }
      this.showLoading(true);
      this.login_submittion = true;
      this.error = null;
      try {
        const accounts = await authservice.getRelatedEmailAcounts({
          email: values.email,
        });
        if (accounts.data.length < 1) {
          this.error = "Aucun compte lié à cet email";
          this.showLoading(false);
          this.login_submittion = false;
          return;
        } else if (accounts.data.length > 1) {
          this.accounts = accounts.data;
          this.login_submittion = false;
          this.showLoading(false);
        } else {
          const response = this.authUser({
            email: values.email,
            password: values.password,
            host: accounts.data[0].host,
          });
          this.showLoading(false);
          this.login_submittion = false;
        }
      } catch (err) {
        this.showLoading(false);
        if (err.response.status === 500) {
          this.error = "Erreur de connexion avec le serveur";
          return { message: errorMsg, status: "error" };
        }
      }
    },

    authUser(user) {
      this.showLoading(true);
      this.login_submittion = true;
      this.signIn(user).then((res) => {
        if (res.status == "error") {
          this.error = res.message;
          this.$toast.error("Authentfication échouée", {
            position: "top-right",
            timeout: 5000,
          });
        } else {
          this.error = null;
          this.$toast.success("Connexion réussie", {
            position: "top-right",
            timeout: 5000,
          });
          this.$router.push({ name: "Pos" });
          this.showLoading(false);
        }
        this.login_submittion = false;
      });
    },
  },
  computed: {},
};
</script>
<style scoped>
h1 {
  text-transform: uppercase;
  font-size: 20px;
  color: #333;
}
img {
  width: 200px;
  margin-bottom: 20px;
}
</style>
